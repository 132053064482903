<template>
  <v-container grid-list-md>
    <v-layout column>
      <v-flex py-2>
        <h1 class="text-h1">{{ $t("notFound.title") }}</h1>
        <h3 class="text-h3">{{ $t("notFound.message") }}</h3>
      </v-flex>
      <v-flex>
        <v-btn rounded color="roundButton" :to="{ path: '/' }">
          {{ $t("notFound.backTo") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
